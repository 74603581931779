<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Тест
            </VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  small
                  color="blue"
                  v-bind="attrs"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPICS,
                    params : {
                      testId : testId
                    }
                  }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-list
                  </VIcon>
                </VBtn>
              </template>
              <span>Список топиков</span>
            </VTooltip>
          </VToolbar>

          <VCardText>
            <VRow>
              <VCol>
                <VExpansionPanels
                  accordion
                  flat
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <code
                        class="elevation-0 d-block"
                        style="white-space: pre"
                      >
                        {{ cachedTest }}
                      </code>
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>
              </VCol>
            </VRow>

            <VDivider />

            <VRow>
              <VCol cols="6">
                <VTextField
                  v-model="testItem.name"
                  label="Название"
                />

                <div>
                  <VSelect
                    v-if="skillItem.id"
                    v-model="testItem.skillId"
                    label="Тест для навыка"
                    class="py-0 ma-0"
                    :items="skills"
                    item-value="id"
                    item-text="name"
                  />
                  <span v-if="!skillItem.id">N/A</span>
                </div>

                <VTextField
                  v-model="testItem.author"
                  label="Автор"
                />

                <VTextarea
                  v-model="testItem.originalDescription"
                  label="Описание"
                />

                <VExpansionPanels
                  v-if="testItem.description"
                  class="my-4"
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>Описание (HTML)</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <!-- eslint-disable vue/no-v-html -->
                      <VCard
                        class="pa-2"
                        outlined
                        tile
                        v-html="testItem.description"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>

                <VTextarea
                  v-model="testItem.originalThankyou"
                  label="Завершение"
                />

                <VExpansionPanels
                  v-if="testItem.thankyou"
                  class="my-4"
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>Завершение (HTML)</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <!-- eslint-disable vue/no-v-html -->
                      <VCard
                        class="pa-2"
                        outlined
                        tile
                        v-html="testItem.thankyou"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>

                <VTextarea
                  v-model="testItem.originalExtra"
                  label="Экстра"
                />

                <VExpansionPanels
                  v-if="testItem.extra"
                  class="my-4"
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>Экстра (HTML)</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <!-- eslint-disable vue/no-v-html -->
                      <VCard
                        class="pa-2"
                        outlined
                        tile
                        v-html="testItem.extra"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>

                <VTextField
                  v-model.number="testItem.settings.choices"
                  type="number"
                  label="Количество ответов"
                />

                <div class="mb-2">
                  <p class="mb-0">
                    Активность
                  </p>
                  <VIcon :color="testItem.status === 'active' ? 'success' : 'warning'">
                    {{ testItem.status === 'active' ? 'fal fa-check-circle' : 'fal fa-times-circle' }}
                  </VIcon>
                </div>

                <div class="mb-2">
                  <p class="mb-0">
                    Уровень
                  </p>
                  <span><strong class="text--black">{{ types[testItem.type] }}</strong></span>
                </div>

                <VCheckbox
                  v-model="testItem.settings.extraLevel"
                  label="Экстра уровень"
                  :disabled="testItem.type === 'Test::Binary'"
                />

                <VCheckbox
                  v-model="testItem.settings.showRightAnswer"
                  label="Показывать верный ответ"
                />

                <VTextField
                  v-model.number="testItem.settings.passValue"
                  label="Значение прохождения"
                />

                <VTextField
                  v-model.number="testItem.settings.setSize"
                  label="Вопросов в наборе"
                />

                <VTextField
                  v-model.number="testItem.settings.timeout"
                  label="Таймаут"
                />

                <VTextField
                  v-model.number="testItem.settings.cooldown"
                  label="Куллдаун"
                />

                <VBtn
                  color="primary"
                  @click="update"
                >
                  Сохранить
                </VBtn>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'TestEdit',
  inject: ['Names'],
  data() {
    return {
      cachedTest: {},
      testItem: {
        type: '',
        thankyou: '',
        description: '',
        author: '',
        extra: '',
        settings: {
          choices: 0,
          extraLevel: false,
          passValue: 0,
          setSize: 0,
          timeout: 60,
          cooldown: 24,
        },
      },
      types: {
        'Test::Eight': 'Многоуровневый',
        'Test::Binary': 'Базовый',
      },
      skillItem: {},
      skills: [],
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  async created() {
    try {
      const res = await this.$di.api.Talent.testGet({ testId: this.testId });
      this.testItem = res;
      this.cachedTest = cloneDeep(res);
      this.$di.api.CompetencyMatrix
        .SkillsIndex()
        .then((response) => {
          this.skills = response.skills;
        })
        .catch(this.$di.notify.errorHandler);
    } catch (err) {
      this.$di.notify.errorHandler(err);
    }

    if (this.testItem.skillId) {
      this.$di.api.CompetencyMatrix
        .SkillsGet({ id: this.testItem.skillId })
        .then((response) => {
          this.skillItem = response.skill;
        })
        .catch(this.$di.notify.errorHandler);
    }
  },
  methods: {
    async update() {
      // Note: edited markdown should send as {field} instead of {original_field}
      // swap values :"(
      // SEE: #MNB-437
      const { testItem } = this;
      testItem.description = testItem.originalDescription;
      testItem.thankyou = testItem.originalThankyou;
      testItem.extra = testItem.originalExtra;

      await this.$di.api.Talent
        .testUpdate({
          testId: this.testId,
          ...omit(testItem, ['originalDescription', 'originalThankyou', 'originalExtra']),
        })
        .then((res) => {
          this.testItem = res;
          this.cachedTest = cloneDeep(res);

          this.$di.notify.snackSuccess('Тест успешно обновлен');
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
